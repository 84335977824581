import PageRequest from "@/store/entities/page-request";
import PagedConsolidationLevelJobResultRequest
    from "@/store/entities/jobcontext/pagedrequest/consolidationleveljobrequest";
import PagedJobLevelJobResultRequest from "@/store/entities/jobcontext/pagedrequest/jobleveljobrequest";

export default class JobPageRequest extends PageRequest {
    jobNumber: number;
    consolidationNumber: number;
    clientId: number;
    originId: number;
    destinationId: number;
    shippingOrderNumber: number;
    consolidationLevelFiler: PagedConsolidationLevelJobResultRequest;
    jobLevelFiler: PagedJobLevelJobResultRequest;
    consolidationId: number;
    nodeId: number;
    isCompleted: number;
    tags: Array<number>

    constructor() {
        super();
        this.consolidationLevelFiler = new PagedConsolidationLevelJobResultRequest();
        this.jobLevelFiler = new PagedJobLevelJobResultRequest();
        this.tags = new Array<number>()
    }

    public clearInput(){
        this.consolidationLevelFiler = new PagedConsolidationLevelJobResultRequest();
        this.jobLevelFiler = new PagedJobLevelJobResultRequest();
        this.jobNumber = null;
        this.consolidationNumber = null;
        this.clientId = null;
        this.originId = null;
        this.destinationId = null;
        this.shippingOrderNumber = null;
    }

    public getCopy() : JobPageRequest{
        return (JSON.parse(JSON.stringify(this)));
    }
}
