export default class PagedJobLevelJobResultRequest {
    shippingMethod: number;
    incotermId: number;
    insurancePolicyNumber: string;
    placeOfReceiptId: number;
    finalDestinationId: number;
    supplierId: number;
    consigneeId: number;
    transportCompanyId: number;
    originCustomBrokerId: number;
    destinationCustomsBrokerId: number;
    transportCompanyDestinationId: number;
    cargoReportingAgentId: number;
    po: string;
    bookingNumber: string;
    containerRelease: string;
    originTransportReference: string;
    exportClearanceNumber: string;
    hbl: string;
    hawb: string;
    destinationTransportReference: string;
    containerId: number;
    sealNumber: string;
}
