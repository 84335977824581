











































































































































import {Component, Prop} from "vue-property-decorator";
import AbpBase from "../../../../../lib/abpbase";
import PagedJobLevelJobResultRequest
    from "../../../../../store/entities/jobcontext/pagedrequest/jobleveljobrequest";
import RemoteFilterableSelect from "@/components/RemoteFilterableSelect.vue";
import FilterableSelect from "@/components/FilterableSelect.vue";
import KeyWordLookUpRequest from "@/store/entities/key-word-look-up-request";
import CompanyKeyWordLookUpRequest from "@/store/entities/ordercontext/company-keyword-lookup-request";

@Component({
    components: {
        RemoteFilterableSelect, FilterableSelect
    }
})
export default class JobLevelJobRequestForm extends AbpBase {
    @Prop({default: new PagedJobLevelJobResultRequest()}) request: PagedJobLevelJobResultRequest;

    private async loadShippingMethods(){
        return await this.$store.dispatch('order/shippingMethods');
    }

    private async loadIncoterms(){
        return await this.$store.dispatch('incoterm/lookUp')
    }

    private async loadContainers(query: string){
        return await this.$store.dispatch('container/lookUp', query);
    }

    private async loadCompanies(query: string, code: string) {
        return await this.$store.dispatch('company/keyWordLookUp', new CompanyKeyWordLookUpRequest(query, -1, code));
    }

    private async loadSupplierCategoryCompanies(query: string) {
        return await this.loadCompanies(query, '03');
    }

    private async loadConsigneeCategoryCompanies(query: string) {
        return await this.loadCompanies(query, '02');
    }

    private async loadTransportCategoryCompanies(query: string) {
        return await this.loadCompanies(query, '04');
    }

    private async loadTradeTransportLocations(query: string) {
        return await this.$store.dispatch('tradetransportlocation/keyWordLookUp', new KeyWordLookUpRequest(query, -1));
    }

    private async loadCustomBrokerCategoryCompanies(query: string) {
        return await this.loadCompanies(query, '05');
    }

    private async loadContainerFreightStationCategoryCompanies(query: string) {
        return await this.loadCompanies(query, '10');
    }
}
