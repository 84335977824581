import { render, staticRenderFns } from "./job-create.vue?vue&type=template&id=2db76a0e&"
import script from "./job-create.vue?vue&type=script&lang=ts&"
export * from "./job-create.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports