import KeyWordLookUpRequest from "@/store/entities/key-word-look-up-request";

export default class CompanyKeyWordLookUpRequest extends KeyWordLookUpRequest {
    code: string

    constructor(keyword: string, id: number | null, code: string) {
        super(keyword, id);
        this.code = code;
    }
}
