












































































































































































































import {Component, Prop} from "vue-property-decorator";
import PagedConsolidationLevelJobResultRequest
    from "@/store/entities/jobcontext/pagedrequest/consolidationleveljobrequest";
import AbpBase from "@/lib/abpbase";
import CompanyKeyWordLookUpRequest from "@/store/entities/ordercontext/company-keyword-lookup-request";
import KeyWordLookUpRequest from "@/store/entities/key-word-look-up-request";
import RemoteFilterableSelect from "@/components/RemoteFilterableSelect.vue";
import FilterableSelect from "@/components/FilterableSelect.vue";

@Component({
    components: {
        RemoteFilterableSelect, FilterableSelect
    }
})
export default class ConsolidationLevelJobRequestForm extends AbpBase {
    @Prop({default: new PagedConsolidationLevelJobResultRequest()}) request: PagedConsolidationLevelJobResultRequest;

    private async loadCompanies(query: string, code: string) {
        return await this.$store.dispatch('company/keyWordLookUp', new CompanyKeyWordLookUpRequest(query, -1, code));
    }

    private async loadAgentCategoryCompanies(query: string) {
        return await this.loadCompanies(query, '01');
    }

    private async loadColoaderCompanyCategoryCompanies(query: string) {
        return await this.loadCompanies(query, '06');
    }

    private async loadCustomBrokerCategoryCompanies(query: string) {
        return await this.loadCompanies(query, '05');
    }

    private async loadShippingLineCategoryCompanies(query: string) {
        return await this.loadCompanies(query, '07');
    }

    private async loadContainerFreightStationCategoryCompanies(query: string) {
        return await this.loadCompanies(query, '10');
    }

    private async loadTransportCategoryCompanies(query: string) {
        return await this.loadCompanies(query, '04');
    }

    private async loadTradeTransportLocations(query: string) {
        return await this.$store.dispatch('tradetransportlocation/keyWordLookUp', new KeyWordLookUpRequest(query, -1));
    }

    private async loadShippingMethods(){
        return await this.$store.dispatch('order/shippingMethods');
    }

    private async loadVessels(query: string){
        return await this.$store.dispatch('vessel/lookUp', new KeyWordLookUpRequest(query, -1));
    }

    private async loadAirlines(){
        return await this.$store.dispatch('airlinecode/lookUp');
    }
}
