






















import {Component, Prop} from 'vue-property-decorator';
import AbpBase from '../../../../lib/abpbase'
import CreateJob from "@/store/entities/jobcontext/createjob";

@Component
export default class CreateJobForm extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    @Prop({default: null}) consolidationId: number | null;
    createJob: CreateJob = new CreateJob();

    private tobTemplateList: any;

    data() {
        return {
            tobTemplateList: []
        }
    };

    async mounted() {
        this.tobTemplateList = await this.$store.dispatch('jobTemplate/lookUp');
    }

    save() {
        (this.$refs.jobForm as any).validate(async (valid: boolean) => {
            if (valid) {
                this.createJob.consolidationId = this.consolidationId;
                let response = await this.$store.dispatch({
                    type: 'job/create',
                    data: this.createJob
                });
                (this.$refs.jobForm as any).resetFields();
                this.$emit('save-success', response);
                this.$emit('input', false);
            }
        })
    }

    cancel() {
        (this.$refs.jobForm as any).resetFields();
        this.$emit('input', false);
    }

    visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        }
    }
}
