










import {Component, Prop} from "vue-property-decorator";
import AbpBase from "../lib/abpbase";

@Component
export default class RemoteFilterableSelect extends AbpBase {
    @Prop({default: null}) value: number;
    private isLoading: boolean = false;
    private items: any;
    @Prop() loadMethod;

    data() {
        return {
            items: []
        }
    }

    private async loadData(val: string){
        if (val !== null && val.length > 0){
            this.isLoading = true;
            this.items = await this.loadMethod(val);
            this.isLoading = false;
        }
    }

    get inputValue() {
        return this.value;
    }

    set inputValue(newValue) {
        this.$emit('input', newValue);
    }
}
